import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PrevWorkCard() {
    return (
        <Container className="text-center">
            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Garmin International</h4>
                    <p>Software Developer II</p>

                    <p>Promoted to Software Developer II in June 2023.</p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Garmin International</h4>
                    <p>Software Developer I</p>

                    <p>I joined the Garmin device interation team in March 2022, currently I am doing android and iOS development on the dive team.</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">Stonk</h4>
                    <p>iOS Application</p>

                    <p>Stonk is a stock market social media app. You can build watchlists with your friends, join communities, look at chart data, and more! Your groupchats are for memes, not stocks.</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">VRealSoftware</h4>
                    <p>Founder</p>

                    <p>I have formed a company where useful software is developed. We are constantly working on new projects and also taking on new clients.</p>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Pin.Point</h4>
                    <p>iOS Application</p>

                    <p>I created an application where people can report crimes and share their story if they ever fell victim of a crime. It is aviable on the Apple App Store.</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">BVA LLC</h4>
                    <p>Website Development</p>

                    <p>I have been working as a website developer for a couple years now. I built the website from the ground up, now I am maintaing the website and the server.</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">RiActive</h4>
                    <p>iOS Game</p>

                    <p>I have created an iOS game that will test peoples reactiveness while keeping it fun. The game pops up random green and red circles that the player has to click. </p>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Best Buy</h4>
                    <p>Sales Consultant</p>

                    <p>I was a sales consultant in the computer department. I would talk with customers and find them the best product that would work for them. I hanlded the cash register and also helped with new shipments/restocking.</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">Dave & Buster's</h4>
                    <p>Cashier</p>

                    <p>I was a cashier in the winners circle. Customers would come to me to reedem their tickets for prizes or buy more tickets with cash. I was also in charge of unpacking the new shipments and restocking the winners circle.</p>
                </Col>
            </Row>

        </Container>
    );
}

export default PrevWorkCard;
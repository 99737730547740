import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import Hero from '../Components/Hero';


function AboutPage(props) {
    return (
        <div>
            <Hero title={'About Me'} location={'a'} />

            <Container fluid={true} className="About-Me-Page-Container">
                <Row className="justify-content-center">
                    <p className="pl-5 pr-5 text-center ">I was born and raised in Kansas City, Kansas. I graduated in 2017 from Blue Valley North High School, when I graduated I already knew what I wanted to do which was software engenieering.</p>
                    <p className="pl-5 pr-5 text-center ">I had been coding since I was in the 6th grade when my mom brough home a ginormous iMac. I set everything up for her and then started googling how iOS applications worked/developed. This is what kick-started my interest in the world of coding, I spent countless hours looking up documentation and watching YouTube tutorials on how to make an iPhone application.</p>
                    <p className="pl-5 pr-5 text-center ">After tikering with how iOS applications worked my cousin showed me his jailbroken iPod. This really got me interested on how someone could reverse engineer someone else's software and add/remove whatever they wanted. I bought a numerous amount of books about iOS Kernal Explotation and Reverse Engeneering. I consistently tried doing what the big shots were doing by finding exploits and using them to create a jailbreak for everyone to use, but at the time I did not have all the time/resources that they had. I didn't drift away though, instead I used my knowledge of Objective-C and started developing jailbreak tweaks of my own. </p>
                    <p className="pl-5 pr-5 text-center ">As time went on I started taking coding classes at school. This is what introduced me to Java and HTML/CSS, during class I would finish the easy assignment that the teacher gave us and would start looking up more advanced ways to use these languages. This furthered my knowledge of the language so much more than waiting for my teacher to read his book about the language and then try explaining to us what he/she just read. I've always had a huge desire to learn more, that is what makes coding so interesting to me is because something new is always being created/released.</p>
                    <p className="pl-5 pr-5 text-center ">A couple years went by where I was too consumed with sports and a job where I couldn't code as much but it was always in the back of my head. It wasn't until I graduated high school that I full hit the ground running with coding. I went to community college to ammuse my mother because she wanted me to go, I grew tired of it and wanted to get away so I worked my way to get into Missouri State University. The reason I went to Missouri State University wasn't to get a degree, it was more to get away and meet new people. I am a person that has a thinking of 'if you work hard enough, everything will workout' so I felt that I did not need to earn a degree from a college but spend my time working on projects that could help the world in some way.</p>
                    <p className="pl-5 pr-5 text-center ">This is when I founded my company VRealSoftware. I started this company so the projects I created could fall under one name. Now clients can come to my company, see the type of work we do and hire us to develop their idea. I started the company in June of 2020 and will continue to build it from the ground up.</p>
                </Row>
            </Container>

        </div>
    );
}

export default AboutPage;
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import ProjectCard from '../Components/ProjectCard';
import ProjectCarousel from '../Components/ProjectsCarousel';

function ProjectsPage(props) {
    return (

        <div>
            <Hero title="All Personal Projects" location='kc' subTitle=' ' />
            <ProjectCarousel />
        </div>

    );
}

export default ProjectsPage;
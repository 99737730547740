import React from 'react';

// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import Stonk_Carousel from '../Stonk-Components/Stonk-Carousel';
import Stonk_Sign_Up from '../Stonk-Components/Stonk-Sign-Up';

function StonkBetaPage(props) {
    return (
        <div>
            <Hero title={"Stonk Beta Registration"} location={'Fort Collins, Co'} subTitle={"Ever get tired of texting back and forth in a group chat with your friends about who is buying what stock and why? Well, we made an app for that! With stonk you will be able to make watchlists with your friends and share what your plays are! You can also join communities where people post information about stocks and also community watchlists! You can also create public and private watchlists, an app to store all of your past and future stock picks!"} />
            <Stonk_Carousel />
            <Stonk_Sign_Up />
        </div>
    );
}

export default StonkBetaPage;
import React from 'react';

// Components
import Hero from '../Stonk-Components/Stonk-Hero';

function ErrorPage(props) {
    return (
        <div>
            <Hero title={"Page not found"} />
        </div>
    );
}

export default ErrorPage;
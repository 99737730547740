import React, { useEffect } from 'react';
import { useRef, useState } from "react";

function LongCard(props) {

    return (
        <div className="d-inline-block sv-card" onClick={(e) => props.click(props.item)}>
            <img className="sv-card-img" src={props.item.imgSrc} alt={props.item.imgSrc} />
            <h1>This is a card</h1>
        </div>
    );

}

export default LongCard;
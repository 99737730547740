import React from 'react';

// Components
import Hero from '../Components/Hero';
import Carousel from '../Components/Carousel';
import AboutMeSection from '../Components/AboutMeSection';
import PreviousWork from '../Components/PreviousWork';
import GoToSection from '../Components/GoToSection';
import Education from '../Components/Education';
import DownloadOniOSAppStoreView from '../Stonk-Components/DownloadOniOSAppStoreView';

import vid1 from '../Assets/Videos/Stonk.mp4';

function HomePage(props) {
    return (
        <div>
            <Hero title={props.title} location={'Broomfield, CO'} subTitle={props.subTitle} />
            
            <div className="flex-container">
                <div className='BigVideo'>
                    <video autoPlay loop muted playsInline id="video" controls>
                        <source src={vid1} type='video/mp4' />
                    </video>
                </div>

                <div className='DownloadOniOSAppStoreView'>
                    <DownloadOniOSAppStoreView />
                </div>
            </div>

            <AboutMeSection />
            
            <PreviousWork />

            <Education />

        </div>
    );
}

export default HomePage;
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from '../Components/Card';

// Images
import pinPointImg from '../Assets/Images/Pin_Point_Img.png';
import followAlongImg from '../Assets/Images/Follow_Along.png';
import Stonk from '../Assets/Images/Stonk.png';

import vid1 from '../Assets/Videos/Stonk_Video.mp4';

class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: "Le-Cube",
                    subTitle: "",
                    imgSrc: followAlongImg,
                    vidSrc: "",
                    link: "https://apps.apple.com/us/app/le-cube/id1501422553",
                    selected: false
                },
                {
                    id: 1,
                    title: "Stonk",
                    subTitle: "Stock Social Media App",
                    imgSrc: Stonk,
                    vidSrc: vid1,
                    link: "https://www.stonk-app.com",
                    selected: false
                },
                {
                    id: 2,
                    title: "Pin.Point",
                    subTitle: "",
                    imgSrc: pinPointImg,
                    vidSrc: "",
                    link: "https://apps.apple.com/us/app/pin-point/id1521115897",
                    selected: false
                }
            ]
        }
    }

    handleCardClick = (id, card) => {
        let items = [...this.state.items];

        items[id].selected = items[id].selected ? false : true;

        items.forEach(item => {
            if (item.id !== id) {
                item.selected = false;
            }
        });

        this.setState( {
            items
        });
    }

    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id} />
        })
    }

    render() {
        return (
            <Container fluid={true} className="Carousel-Container-sv">
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
            </Container>
        );
    }

}

export default Carousel;
import React from 'react';
import { Form, Jumbotron } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { useHistory } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

import StonkBetaThankYouPage from '../Pages/Stonk-Thank-You';

function Stonk_Sign_Up(props) {

    const history = useHistory();

    var firebaseConfig = {
        apiKey: "AIzaSyBSqi1vH26tlNN6iv0qJ9jMhVHM9K5AjLo",
        authDomain: "nexttrade-68e7c.firebaseapp.com",
        databaseURL: "https://nexttrade-68e7c-default-rtdb.firebaseio.com",
        projectId: "nexttrade-68e7c",
        storageBucket: "nexttrade-68e7c.appspot.com",
        messagingSenderId: "609024077835",
        appId: "1:609024077835:web:9e6816430d0cb22c9ad70e",
        measurementId: "G-W0XJCWEX5T"
    };

    // Initialize Firebase
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
     }else {
        firebase.app(); // if already initialized, use that one
     }

    var database = firebase.database();

    function uniqueID(){
        function chr4(){
          return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() + chr4() + chr4();
    }

    function handle_submit(e) {
        e.preventDefault();

        var uid = uniqueID()


        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;

        if (name != '' && email != '') {
            firebase.database().ref('Beta_Users/' + uid + "/").set({
                name: document.getElementById("name").value,
                email: document.getElementById("email").value
            }).then(function() {
                history.push("/Stonk-Beta-Thank-You");
            });
        }
    }

    return (
        <Container fluid={true}>
            <Row className="justify-content-center py-5">
                <Col md={9} sm={12}>
                    <Form onSubmit={handle_submit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="Name" id="name" placeholder="Your name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="Email" id="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                                Your email will be used to send a link to download the beta!
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Sign up for Beta
                        </Button>
                    </Form>
                </Col>

            </Row>
        </Container>
    );
}

export default Stonk_Sign_Up;


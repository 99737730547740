import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { browserHistory } from 'react-router';
import Container from "react-bootstrap/Container";
import NavBar from "react-bootstrap/NavBar";
import Nav from "react-bootstrap/Nav";
import './App.css';

// Components
import Footer from './Components/Footer';
import HomePage from './Pages/HomePage';
import ProjectsPage from './Pages/ProjectsPage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import StonkBetaPage from './Pages/Stonk-Beta-Page';
import StonkBetaThankYouPage from './Pages/Stonk-Thank-You';
import ErrorPage from './Pages/ErrorPage';

const linkStyle = {
  color: '#2783d9'
};

class App extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {
      color: "#3b3b3b",
      title: 'Spencer Villarreal',
      headerLinks: [
        {title: 'Home', path: '/'},
        {title: 'Projects', path: '/Projects'},
        {title: 'About', path: '/About'},
        {title: 'Contact', path: '/Contact'},
        {title: 'Stonk Beta', path: 'https://www.stonk-app.com'},
        {title: 'Thank you for singing up for the Stonk Beta', path: '/Stonk-Beta-Thank-You'}
      ],
      home: {
        title: 'Spencer Villarreal Portfolio',
        subTitle: 'Featured Project'
      },
      projects: {
        title: 'Projects I have Developed'
      },
      about: {
        title: 'About Me'
      },
      contact: {
        title: 'Let\'s Work Together'
      }
    }
  }

  render() {
    return (
      <Router history={browserHistory}>
        <Container className="p-0" fluid={true}>
          
          <NavBar className="border-bottom" bg="transparent" expand="md">
            <NavBar.Brand>VRealSoftware</NavBar.Brand>

            <NavBar.Toggle className="border-0" aria-controls="navBar-toggle" />
            <NavBar.Collapse id="navBar-toggle">
              <Nav className="ml-auto">
                <Link className="nav-link" to="/">Home</Link>
                <Link className="nav-link" to="/Projects">Projects</Link>
                <Link className="nav-link" to="/About">About Me</Link>
                <Link className="nav-link" to="/Contact">Contact</Link>
                <Link className="nav-link" onClick={()=> window.open("https://www.stonk-app.com")} style={linkStyle}>Stonk Beta</Link>
              </Nav>
            </NavBar.Collapse>
          </NavBar>

          <Switch>
            <Route path="/" exact render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} />} />
            <Route path="/Projects" render={() => <ProjectsPage title={this.state.projects.title} />} />
            <Route path="/About" render={() => <AboutPage title={this.state.about.title} />} />
            <Route path="/Contact" render={() => <ContactPage title={this.state.contact.title} />} />
            <Route path="/Stonk-Beta" render={() => <StonkBetaPage title={this.state.contact.title} />} />
            <Route path="/Stonk-Beta-Thank-You" render={() => <StonkBetaThankYouPage title={this.state.contact.title} />} />
            <Route component={ErrorPage} />
          </Switch>
      
          <Footer />

        </Container>
      </Router>
    );
  }
}

export default App;

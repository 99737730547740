import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import Hero from '../Stonk-Components/Stonk-Hero';
import Stonk_Carousel from '../Stonk-Components/Stonk-Carousel';
import Stonk_Sign_Up from '../Stonk-Components/Stonk-Sign-Up';

function StonkBetaThankYou(props) {
    return (
        <Container fluid={true}>
            <Hero title={"Thank you for signing up for the Stonk beta"} />

            <Row className="justify-content-center">
                <p className="pl-5 pr-5 text-center ">Once the beta is live you will recieve an email with a download link. Inside of the app there will be an area to report
                    issues with the app or suggestions! It would be very helpful to us for you to leave as much feedback as possible! This 
                    project has been in the works for over 5 months now and might be a little shaky at the start! Please be paitent with us 
                    at the beginning because the main developer is also working a full time job and creating this app on the side! I hope 
                    that this app is able to help you and your friends make money in the stock market and make it a better expierence!
                </p>
            </Row>
        </Container>
    );
}

export default StonkBetaThankYou;
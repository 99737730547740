import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarReg } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AboutMeLeftCard() {
    return (
        <Container className="text-center">
            <Row className="mt-3">
                <Col>
                    <p className="border-bottom">Swift</p>
                    <p>6 Years</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></p>
                </Col>

                <Col>
                    <p className="border-bottom">HTML/CSS</p>
                    <p>4 Years</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarReg} /></p>
                </Col>

                <Col>
                    <p className="border-bottom">Java</p>
                    <p>7 Years</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarReg} /><FontAwesomeIcon icon={faStarReg} /></p>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <p className="border-bottom">React</p>
                    <p>1 Year</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarReg} /><FontAwesomeIcon icon={faStarReg} /><FontAwesomeIcon icon={faStarReg} /></p>
                </Col>

                <Col>
                    <p className="border-bottom">C/C++</p>
                    <p>3 Years</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarReg} /><FontAwesomeIcon icon={faStarReg} /><FontAwesomeIcon icon={faStarReg} /></p>
                </Col>

                <Col>
                    <p className="border-bottom">Python</p>
                    <p>10 Years</p>
                    <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMeLeftCard;
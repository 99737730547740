import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Hero(props) {
    return (
        <Jumbotron className="bg-transparent jumbotron-fluid p-0">
            <Container fluid={true}>
                <Row className="justify-content-center py-5">
                    <Col md={9} sm={12}>
                        { props.title && <h1 className="display-4 font-weight-bolder text-center">{props.title}</h1> }
                        { props.location && <p className="text-center border-bottom">{props.location}</p> }
                        { props.subTitle && <h3 className="display-5 font-weight-light text-center">{props.subTitle}</h3> }
                    </Col>

                </Row>
            </Container>
        </Jumbotron>
    );
}

export default Hero;
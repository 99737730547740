import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// FontAwesome
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
    return (
        <footer className="mt-5">
            <Container fluid={true}>
                <Row className="border-top justify-content-between p-3">
                    <Col className="p-0" md={3} sm={12}>
                        <p>Version 1.0.2</p>
                    </Col>

                    <Col className="justify-content-center text-center">
                        <p>spencervillarreal@vrealsoftware.com</p>
                        <a href={'https://www.linkedin.com/in/spencer-v-34b599141/'} target="_blank" rel="noopener noreferer" style={{color: '#212529'}}><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
                        <a className="pl-2" href={'https://www.youtube.com/channel/UC0PXql-It0caIzZQ_u3xbfw'} target="_blank" rel="noopener noreferer" style={{color: '#212529'}}><FontAwesomeIcon icon={faYoutube} size="2x" /></a>
                        <a className="pl-2" href={'https://www.facebook.com/spencer.villarreal.7/'} target="_blank" rel="noopener noreferer" style={{color: '#212529'}}><FontAwesomeIcon icon={faFacebookSquare} size="2x" /></a>
                        <a className="pl-2" href={'https://www.instagram.com/spencerv2/'} target="_blank" rel="noopener noreferer" style={{color: '#212529'}}><FontAwesomeIcon icon={faInstagramSquare} size="2x" /></a>
                    </Col>

                    <Col className="p-0 d-flex justify-content-end" md={3}>
                        VRealSoftware LLC
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import EducationCard from '../Components/EducationCard';

function Education() {
    return (
        <Container fluid={true} className="mt-5">
            <h3 className="display-5 font-weight-light text-center border-bottom">Education</h3>

            <Row className="justify-content-center mt-3">
                <Col md={11} sm={12} className="">
                    <EducationCard />
                </Col>
            </Row>
        </Container>
    );
}

export default Education;
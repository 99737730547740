import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EducationCard() {
    return (
        <Container className="text-center">
            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Blue Valley North High School</h4>
                    <p>Graduated 2017</p>
                </Col>

                <Col>
                    <h4 className="border-bottom">Johnson County Community College</h4>
                    <p>Major - Computer Science</p>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <h4 className="border-bottom">Missouri State University</h4>
                    <p>Major - Computer Science</p>
                    <p>Minor - Cyber Security</p>
                </Col>
            </Row>
        </Container>
    );
}

export default EducationCard;